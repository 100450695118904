/* eslint-disable @typescript-eslint/no-explicit-any */
import { AtUri } from '@atproto/api';
import { ViewImage } from '@atproto/api/dist/client/types/app/bsky/embed/images';
import { QueryClient } from '@tanstack/react-query';
import { FEED_LIST_QUERY_KEY } from '~/config/query';
import { FeedType, FeedTypes, PostRecord } from '~/config/types';
import { atpClient } from './api/agent';
import { getDid } from './utils/did';

// Function to create a random 16-character string
export const createRandomString = (length: number = 8) => {
  // Create a string with possible characters (numbers and letters)
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let result = '';

  // Generate a random string of the specified length
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * characters.length));
  }

  return result;
};

// Function to show a timestamp in the format like '5m'
export function timeAgo(timestamp: string): string {
  const now = new Date();
  const past = new Date(timestamp);
  // Check if the "past" date is valid
  if (isNaN(past.getTime())) {
    return '';
  }

  const diffInMs = Number(now) - Number(past);
  // Return empty string if the result is negative
  if (diffInMs < 0) {
    return '';
  }

  const seconds = Math.floor(diffInMs / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);
  const months = Math.floor(days / 30);
  const years = Math.floor(days / 365);

  // Return in short format
  if (years > 0) {
    return `${years}y`;
  } else if (months > 0) {
    return `${months}mo`;
  } else if (days > 0) {
    return `${days}d`;
  } else if (hours > 0) {
    return `${hours}h`;
  } else if (minutes > 0) {
    return `${minutes}m`;
  } else {
    return `${seconds}s`;
  }
}

// Utility function to chunk an array into smaller arrays of a given size
export const chunkArray = <T>(array: T[], chunkSize: number): T[][] => {
  const chunks = [];
  for (let i = 0; i < array.length; i += chunkSize) {
    chunks.push(array.slice(i, i + chunkSize));
  }
  return chunks;
};

// Check if type is PostRecord
export const isPostRecord = (record: any): record is PostRecord => {
  return record?.$type === 'app.bsky.feed.post';
};

// Check if type is ViewImageArray
export const isViewImageArray = (images: any): images is ViewImage[] => {
  return (
    Array.isArray(images) && // Ensure it is an array
    images.every(
      (image) =>
        typeof image.thumb === 'string' &&
        typeof image.fullsize === 'string' &&
        typeof image.alt === 'string' &&
        image.aspectRatio &&
        typeof image.aspectRatio.height === 'number' &&
        typeof image.aspectRatio.width === 'number',
    )
  );
};

export const truncate = (text: string, len: number) => {
  return text.length > len ? text.substring(0, len) + '...' : text;
};

export const refreshFeedList = async (queryClient: QueryClient) => {
  // Invalidate Query
  console.log('Refresh feed list...');
  const did = await getDid();
  queryClient.invalidateQueries({ queryKey: [FEED_LIST_QUERY_KEY, did] });
};

export const generateUrlString = (input: string): string => {
  // Replace spaces with dashes
  const inputWithDashes = input.replace(/\s+/g, '-');

  // Remove any non-alphanumeric characters (except for dashes and underscores)
  const sanitizedInput = inputWithDashes.replace(/[^a-zA-Z0-9-_]/g, '');

  // Convert the string to lowercase
  const lowerCaseInput = sanitizedInput.toLowerCase();

  // Truncate the string to a maximum of 15 characters
  const truncatedString = lowerCaseInput.substring(0, 15);

  return truncatedString;
};

export const getAtUri = (identifier: string): AtUri => {
  return new AtUri(`at://${atpClient.agent.session?.did}/app.bsky.feed.generator/${identifier}`);
};

export const isFeedType = (type: string): type is FeedType => {
  return type in FeedTypes;
};

export const getAvatarUrl = (avatar: string | File | undefined, updatedAt: string | undefined): string => {
  if (avatar instanceof File) return URL.createObjectURL(avatar);
  // Bust cache by adding a query parameter with the updatedAt timestamp
  if (avatar?.startsWith('blob:')) return avatar;
  return avatar ? (updatedAt ? `${avatar}?u=${updatedAt}` : avatar) : '';
};
