import { AtUri, Did } from '@atproto/api';
import { IconName } from '~/components/robin/RobinIcons';
import { ActionMenuItemStyle, FeedOperator, FeedState, NavBtnStyle, Preset } from './enums';

export const FeedTypes: Record<Preset, { icon: IconName }> = {
  [Preset.Hashtag]: { icon: 'hashtag' },
  [Preset.Search]: { icon: 'search' },
  [Preset.Author]: { icon: 'users' },
  [Preset.Mention]: { icon: 'atSign' },
  [Preset.Advanced]: { icon: 'settings' },
  [Preset.Collection]: { icon: 'tags' },
} as const;

export type FeedType = keyof typeof FeedTypes;
export type FeedNetwork = 'bluesky' | 'farcaster';

export interface PublishRecord {
  identifier: string;
  slug: string;
  displayName: string;
  description: string;
  avatar: string;
}

export interface FeedItem {
  identifier: string;
  type: FeedType;
  createdAt: string;
  updatedAt: string;
  displayName: string;
  description: string;
  avatar: string;
  authors: string[];
  hashtags: string[];
  mentions: string[];
  search: string[];
  atUris: string[];
  excludeAuthors: string[];
  excludeHashtags: string[];
  excludeMentions: string[];
  excludeSearch: string[];
  excludeAtUris: string[];
  pinned: boolean;
  bookmark: boolean;
  atUri: AtUri;
  state: FeedState;
  slug: string;
  operator: FeedOperator;
  collections: string[];
}

export interface Action {
  label: string;
  iconName: IconName;
  onClick?: () => void;
  visible?: boolean;
  btnStyle?: NavBtnStyle;
  menuItemStyle?: ActionMenuItemStyle;
  href?: string;
}

export interface PresetConfig {
  text: string;
  network: FeedNetwork;
  type: FeedType;
  q: string;
  includeAllLabel: string;
  name: string;
}

export interface PostRecord {
  $type: 'app.bsky.feed.post';
  text: string;
  createdAt: string;
}

export interface uploadAvatarResponse {
  url: string;
}

export type UserInfo = {
  did: Did;
  handle: string;
  email: string;
  emailConfirmed: boolean;
  emailAuthFactor: boolean;
  accessJwt: string;
  refreshJwt: string;
  active: boolean;
};

export type Profile = {
  did: Did;
  avatar: string;
  displayName: string;
  handle: string;
  indexedAt: string;
};

export type InputData = {
  identifier?: string;
  slug?: string;
  displayName: string;
  description: string;
  mentions: string[];
  authors: string[];
  search: string[];
  hashtags: string[];
  avatar: string | File;
  publishToBsky: boolean;
  excludeSearch: string[];
  excludeHashtags: string[];
  excludeMentions: string[];
  excludeAuthors: string[];
  atUris: string[];
  excludeAtUris: string[];
  includeAll: boolean;
  updatedAt: string;
  type: Preset;
  collections: string[];
};
