import { NavBtnStyle } from '~/config/enums';
import { NavBtnStyles } from '~/config/styles';
import { cn } from '~/lib/utils';
import { IconName, renderIcon } from './RobinIcons';

interface NavButtonProps {
  ariaLabel: string;
  iconName: IconName;
  onClick?: () => void;
  className?: string;
  btnStyle?: NavBtnStyle;
  disabled?: boolean;
  size?: 4 | 5 | 6 | 10;
}

export default function RobinNavButton({ ariaLabel, iconName, onClick, className = '', btnStyle = NavBtnStyle.Default, disabled = false, size = 6 }: NavButtonProps) {
  return (
    <button onClick={onClick} className={cn('flex items-center justify-center w-10 h-9 rounded-2xl transition', NavBtnStyles[btnStyle], className)} aria-label={ariaLabel} disabled={disabled}>
      {renderIcon(iconName, size)}
    </button>
  );
}
