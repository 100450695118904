import { ReactNode, useCallback, useRef, useState } from 'react';
import { Shape } from '~/config/enums';
import { cn } from '~/lib/utils';
import { IconName, renderIcon } from './robin/RobinIcons';

interface AvatarProps {
  avatarUrl?: string | null;
  placeHolderIcon: IconName;
  size?: 6 | 8 | 10 | 14 | 16 | 20;
  className?: string;
  onClick?: () => void;
  altText?: string;
  shape?: 'circle' | 'square';
  loadingAnimation?: boolean;
  metaIcon?: ReactNode;
}

const sizeClasses = {
  6: 'w-6 h-6',
  8: 'w-8 h-8',
  10: 'w-10 h-10',
  14: 'w-14 h-14',
  16: 'w-16 h-16',
  20: 'w-20 h-20',
};

export function Avatar({ avatarUrl, placeHolderIcon, size = 10, className = '', onClick, altText = 'Feed Avatar', shape = Shape.Square, loadingAnimation = true, metaIcon }: AvatarProps) {
  const [imageError, setImageError] = useState(false);
  const isLoading = useRef<boolean | undefined>(loadingAnimation ? true : false);
  const imageRef = useRef<HTMLImageElement | null>(null);
  const roundedClass = shape === Shape.Circle ? 'rounded-full' : 'rounded';

  const onLoad = useCallback(() => {
    isLoading.current = true;
    imageRef.current?.classList.remove('opacity-0');
    if (!imageRef.current?.classList.contains('opacity-100')) imageRef.current?.classList.add('opacity-100');
  }, []);

  const onImageMounted = useCallback((el: HTMLImageElement) => {
    if (!imageRef.current) imageRef.current = el;
    if (!isLoading.current && !el?.classList.contains('opacity-100')) el?.classList.add('opacity-100');
    if (isLoading.current) {
      el?.classList.add('opacity-0');
      el?.classList.remove('opacity-100');
    }
  }, []);

  const avatarClass = cn('flex relative aspect-square justify-center items-center', roundedClass, sizeClasses[size], className);

  const avatarContent =
    avatarUrl && !imageError ? (
      <>
        <img
          src={avatarUrl}
          alt={altText}
          className={cn('object-cover w-full h-full z-20 transition-opacity duration-300', roundedClass)}
          ref={onImageMounted}
          onError={setImageError.bind(null, true)}
          onLoad={onLoad}
        />
        <div className={cn('left-0 top-0 w-full h-full', avatarClass, roundedClass, 'bg-skeleton border border-gray-200 absolute z-10')} />
        {metaIcon}
      </>
    ) : (
      <div className="text-rf-gray-75 w-6 h-6">
        {renderIcon(placeHolderIcon)}
        {metaIcon}
      </div>
    );

  return onClick ? (
    <button className={cn('relative', avatarClass)} onClick={onClick} aria-label="Avatar button">
      {avatarContent}
    </button>
  ) : (
    <div className={cn(avatarClass, 'relative')}>{avatarContent}</div>
  );
}
