import { Did } from '@atproto/api';
import { getAgentSession } from '~/services/api/auth';
import { atpClient } from '../api/agent';

// Helper function to retrieve the DID, ensuring the session is valid
export const getDid = async (): Promise<Did> => {
  const did = atpClient.agent.session?.did;
  if (!did) {
    await getAgentSession({
      forceRefresh: true,
    }).then(() => {
      return atpClient.agent.session?.did;
    });
    console.log('No DID available. Please log in.');

    // throwSessionError();
  }
  return did as Did;
};
